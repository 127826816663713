import React, {FunctionComponent, SyntheticEvent} from 'react';
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {Dropdown, DropdownItemProps, DropdownProps} from "semantic-ui-react";


interface SwitchLanguageProps {
    history: any
}



const SwitchLanguage: FunctionComponent<SwitchLanguageProps> = function ({history}) {
    const {t, i18n, ready} = useTranslation();
    if(!ready){
        return <div/>
    }

    const options: DropdownItemProps[] = [
        {key: "de", text: "Deutsch" },
        {key: "en", text: "English"}
    ];

    return <div>
        <Dropdown
            defaultValue={i18n.language}
            style={{width: 120}}
            placeholder={t("choseLanguage")}
            options={options}
            onChange={(event: SyntheticEvent, data: DropdownProps): void => {
                if (typeof data.key !== "undefined") {
                    const oldLanguage = i18n.language;
                    history.push(history.location.pathname.replace(oldLanguage, data.key));
                }
            }}
        />
    </div>;
};

export default withRouter(SwitchLanguage);
