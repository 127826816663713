import React, {lazy} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import SwitchLanguage from "../modules/i18n/SwitchLanguage";
import {useTranslation} from "react-i18next";
import Loader from "../modules/ui/Loader";

const Home = lazy(() => import("./Home")) ;
const CloudPreview = lazy(() => import("./CloudPreview")) ;
const CloudMessage = lazy(() => import("./CloudMessage")) ;
const LawMenu  = lazy(() => import("../modules/law/LawMenu"));

function LangRedirectTo404(){
    const {i18n} = useTranslation();
    return <Redirect to={`/404?language=${i18n.language}`}/>;
}

function LangRedirectToHome(){
    const {i18n} = useTranslation();
    return <Redirect to={`/${i18n.language}`}/>;
}

export default function Pages() {
    return <BrowserRouter>
        <SwitchLanguage/>
        <LawMenu/>
        <Switch>
            <Route exact path="/" component={LangRedirectToHome}/>
            <Route exact path="/:language" component={Home}/>
            <Route exact path="/:language/cloud/preview" component={CloudPreview}/>
            <Route exact path="/:language/cloud/message" component={CloudMessage}/>
            <Route exact path="/de/loader" component={Loader}/>
            <Route path="*" component={LangRedirectTo404}/>
        </Switch>
    </BrowserRouter>;
}

