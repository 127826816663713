import React, {ErrorInfo, PureComponent} from 'react';
import {localStorageKey} from "../../config";

interface ErrorBoundaryPropsType {
}

interface ErrorBoundaryStateType {
    hasError: boolean
}

export default class ErrorBoundary extends PureComponent <ErrorBoundaryPropsType, ErrorBoundaryStateType> {
    constructor(props: ErrorBoundaryPropsType) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.log(info);
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            localStorage.removeItem(localStorageKey);
            // You can render any custom fallback UI
            return <div className="center">
                <h1>Something went wrong, I will try to repair myself</h1>
            </div>;
        }

        return this.props.children;
    }
}

