import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from "./modules/ui/ErrorBoundary";
import Loader from "./modules/ui/Loader";

ReactDOM.render(
    <Suspense fallback={<Loader/>}>
        <ErrorBoundary>
            <App/>
        </ErrorBoundary>
    </Suspense>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
