import React from 'react';
import "./loader.css";
import {sample} from 'lodash';
import i18n from "../i18n";

export default function Loader() {
    let text = "Please wait...";
    if (i18n.language === "de") {
        text = "Bitte warten...";
    }

    const quotesEN = [
        "Longest minutes in life: Waiting for food in the microwave.",
        "Did you now, that you can slow time? Just text your crush, and then wait for a response.",
        "How to slow time: Get on the floor and start planking.",
        "How to slow time: Move very fast!"
    ];

    const quotesDE = [
        "Longest minutes in life: Waiting for food in the microwave.",
        "Did you now, that you can slow time? Just text your crush, and then wait for a response.",
        "How to slow time: Get on the floor and start planking.",
        "How to slow time: Move very fast!"
    ];

    return <div className="flex-column">
        <div className="spinner"></div>
        <div className="container red align-items-start reduced">
            <div>
                <h1>{text}</h1>
                <h2>{sample(i18n.language === "de" ? quotesDE : quotesEN)}</h2>
            </div>
        </div>
    </div>;
}

