import React from 'react';
import Pages from "./pages/Router";

import "./modules/i18n";

import 'semantic-ui-css/semantic.min.css'
import './App.css';
import {useTranslation} from 'react-i18next';
import {Segment} from "semantic-ui-react";


function App() {
    const {t} = useTranslation();
    const rel = {rel: "nofollow"}
    return (
        <div>
            <Pages/>
            <footer className="container red">

                <article className="article demo">
                    <Segment>
                        <div className="center">
                            <p>{t("landing.demo_disclaimer")}</p>

                            <a href="https://zenturee.de/impressum" {...rel} >
                                Impressum
                            </a>
                            {" | "}
                            <a href="https://zenturee.de/impressum" {...rel}>
                                {t("landing.privacy")}
                            </a>
                        </div>
                    </Segment>
                </article>
            </footer>
        </div>
    );
}

export default App;
